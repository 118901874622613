import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  Switch,
  Card,
  Select,
  DatePicker,
  Modal,
  Spin,
  Popover,
  Checkbox,
  Input
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  MinusOutlined,
  CloudDownloadOutlined
} from '@ant-design/icons';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ZoomPlugin from 'chartjs-plugin-zoom';
import dayjs from 'dayjs';
import moment from 'moment';
import _ from 'lodash';
import { useRecoilValue, useRecoilState, useRecoilValueLoadable } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useJsonToCsv } from 'react-json-csv';
import api from '../../../../../../api/api';
import {
  dashboardSelectedStoreAtom,
  dashboardPayloadAtom,
  reloadItemsTrendsAtom
} from '../../../../../../atoms/Atoms';
import {
  configSelector
} from '../../../../../../atoms/Selectors';
import { thousand, toCurrency, percent } from '../../../../../utils/functions';
import { dateRangePresets } from '../../../../../utils/utils';

function ItemsTrends({ className }) {
  const { saveAsCsv } = useJsonToCsv();
  const counts = [5, 10, 15, 20, 25, 30];
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [reload, setReload] = useRecoilState(reloadItemsTrendsAtom);
  const selectedStoreId = useRecoilValue(dashboardSelectedStoreAtom);
  const [dashboardPayload, setDashboardPayload] =
    useRecoilState(dashboardPayloadAtom);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [dates, setDates] = useState({});
  const [compareLastYear, setCompareLastYear] = useState(false);
  const [trendsType, setTrendsType] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [itemCount, setItemCount] = useState(5);
  const config = useRecoilValueLoadable(configSelector);
  const [tableColumns, setTableColumns] = useState([
    {
      title: t('name'),
      key: 'completeName',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.completeName - b.completeName,
      render: (row) => <span>{row.completeName}</span>,
    },
    {
      title: t('UPC'),
      key: 'upc',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.upc - b.upc,
      render: (row) => <span>{row.upc}</span>,
    },
    {
      title: t('sold'),
      key: 'currentOrders',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.currentOrders - b.currentOrders,
      render: (row) => (
        <div className="flex">
          <span>{thousand(row.currentOrders)}</span>
          {row.ordersGrowth === 0 && (
            <MinusOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5"
              aria-hidden="true"
            />
          )}
          {row.ordersGrowth > 0 && (
            <ArrowUpOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          )}
          {row.ordersGrowth < 0 && (
            <ArrowDownOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
          <span className={`${row.ordersGrowth < 0 ? 'text-red-500' : ''} ${row.ordersGrowth > 0 ? 'text-green-500' : ''}`}>{thousand(percent(row.ordersGrowth))}%</span>
        </div>
      ),
    },
    {
      exportOnly: true,
      key: 'sold_growth',
      hidden: false,
      title: t('sales_total_growth')
    },
    {
      title: t('sales_total'),
      key: 'currentSalesTotal',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.currentSalesTotal - b.currentSalesTotal,
      render: (row) => (
        <div className="flex">
          <span>{toCurrency(row.currentSalesTotal)}</span>
          {row.salesTotalGrowth === 0 && (
            <MinusOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5"
              aria-hidden="true"
            />
          )}
          {row.salesTotalGrowth > 0 && (
            <ArrowUpOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          )}
          {row.salesTotalGrowth < 0 && (
            <ArrowDownOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
          <span className={`${row.salesTotalGrowth < 0 ? 'text-red-500' : ''} ${row.salesTotalGrowth > 0 ? 'text-green-500' : ''}`}>{thousand(percent(row.salesTotalGrowth))}%</span>
        </div>
      ),
    },
    {
      exportOnly: true,
      key: 'salesTotalGrowth',
      hidden: false,
      title: t('sales_total_growth')
    },
    {
      title: t('profit'),
      key: 'currentProfit',
      align: 'center',
      className: 'text-xs',
      sorter: (a, b) => a.currentProfit - b.currentProfit,
      render: (row) => (
        <div className="flex">
          <span>{toCurrency(row.currentProfit)}</span>
          {row.profitGrowth === 0 && (
            <MinusOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5"
              aria-hidden="true"
            />
          )}
          {row.profitGrowth > 0 && (
            <ArrowUpOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          )}
          {row.profitGrowth < 0 && (
            <ArrowDownOutlined
              className="ml-auto mt-1 flex-shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}
          <span className={`${row.profitGrowth < 0 ? 'text-red-500' : ''} ${row.profitGrowth > 0 ? 'text-green-500' : ''}`}>{thousand(percent(row.profitGrowth))}%</span>
        </div>
      ),
    },
    {
      exportOnly: true,
      key: 'profitGrowth',
      hidden: false,
      title: t('profit_growth')
    },
    // {
    //   title: t('profability'),
    //   key: 'currentProfability',
    //   hidden: true,
    //   align: 'center',
    //   className: 'text-xs',
    //   sorter: (a, b) => a.currentProfability - b.currentProfability,
    //   render: (row) => (
    //     <div className="flex">
    //       <span>{row.currentProfability}</span>
    //     </div>
    //   ),
    // },
  ]);

  function toggleVisibility(key) {
    const tempColumns = [...tableColumns];
    for (let i = 0; i < tempColumns.length; i++) {
      if (tempColumns[i].key === key) {
        tempColumns[i].hidden = !tempColumns[i].hidden;
      }
    }
    setTableColumns(tempColumns);
  }

  function getData() {
    if (loading) {
      return;
    }
    setLoading(true);
    api
      .post('analytics/v2/trends/items', {
        CompareLastYear: compareLastYear,
        StartDate: moment(dates.start).utc(),
        EndDate: moment(dates.end).utc().add(1, 'days'),
        Type: trendsType,
        BusinessId: selectedStoreId,
        Count: itemCount,
        SearchTerm: searchTerm
      })
      .then((response) => {
        setLoading(false);
        const temp = _.map(response.data.data.comparisons, (c) => ({
          ...c,
          completeName: `${c.brand} ${c.name} ${c.description}`.trim()
        }));
        setData({
          ...response.data.data,
          comparisons: temp
        });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      }).finally(() => {
        setReload(false);
      });
  }

  useEffect(() => {
    if (reload) {
      getData();
    }
  }, [reload]);

  useEffect(() => {
    getData();
  }, [itemCount]);

  useEffect(() => {
    setCompareLastYear(dashboardPayload.compareLastYear);
    setTrendsType(dashboardPayload.trendsType);
  }, [dashboardPayload.compareLastYear, dashboardPayload.trendsType]);

  useEffect(() => {
    const temp = {
      start: dashboardPayload.start,
      end: dashboardPayload.end
    };
    setDates(temp);
  }, [dashboardPayload.start, dashboardPayload.end]);

  useEffect(() => {
    const tempColumns = [...tableColumns];
    for (let i = 0; i < tempColumns.length; i++) {
      tempColumns[i].hidden = false;
    }
    setTableColumns(tempColumns);
  }, [showModal]);

  return (
    <Card
      className={`shadow-lg ${className}`}
      hoverable
    >
      <Spin spinning={loading}>
        <PageHeader
          className="-mt-4 p-0"
          title={t('items')}
          extra={[
            <div className="flex space-x-2">
              <strong className="my-auto">{t('show_top')}</strong>
              <Select
                className="my-auto"
                value={itemCount}
                style={{ width: 60 }}
                onChange={(obj) => setItemCount(obj)}
              >
                {_.map(counts, (c) => (
                  <Option value={c}>{c}</Option>
                ))}
              </Select>
            </div>,
            <Button
              type="primary"
              size="small"
              onClick={() => {
                getData();
              }}
            >
              {t('reload')}
            </Button>
          ]}
        />
        <div className="col-span-1 h-96">
          <Bar
            height={350}
            data={{
              labels: _.map(_.take(data?.comparisons, 5), (s) => `${s.brand} ${s.name} ${s.description}`.trim()),
              datasets: [
                {
                  label: data?.previousLabel,
                  borderColor: config?.contents?.data?.secondaryColor,
                  backgroundColor: `${config?.contents?.data?.secondaryColor}cc`,
                  data: _.map(_.take(data?.comparisons, 5), (x) => x.previousSalesTotal),
                },
                {
                  label: data?.currentLabel,
                  borderColor: config?.contents?.data?.primaryColor,
                  backgroundColor: `${config?.contents?.data?.primaryColor}cc`,
                  data: _.map(_.take(data?.comparisons, 5), (x) => x.currentSalesTotal),
                },
              ],
            }}
            plugins={[ChartDataLabels]}
            options={{
              maintainAspectRatio: false,
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    callback: (value, index, ticks) => toCurrency(value)
                  }
                }],
              },
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: false,
                },
                datalabels: {
                  anchor: 'end',
                  offset: 8,
                  rotation: 330,
                  color: 'black',
                  formatter: (value, context) => toCurrency(value),
                  labels: {
                    title: {
                      font: {
                        weight: 'regular'
                      }
                    }
                  }
                }
              },
            }}
          />
        </div>
        <Button
          className="-mb-4 float-right text-blue-500 border-none"
          onClick={() => setShowModal(true)}
        >
          <span className="underline">{t('view_details')}</span>
        </Button>
      </Spin>
      <Modal
        title={t('items')}
        open={showModal}
        centered
        width="85%"
        onCancel={() => setShowModal(false)}
        footer={[
          <Button
            key="close"
            type="primary"
            danger
            onClick={() => setShowModal(false)}
          >
            {t('close')}
          </Button>
        ]}
      >
        <div className="space-y-4">
          <div className="grid ml-8 md:ml-0 md:flex md:space-x-2 space-y-2">
            <Select
              className="ml-auto my-auto mt-2"
              value={trendsType}
              style={{ width: 160 }}
              onChange={(obj) => setTrendsType(obj)}
            >
              <Option value={0}>{t('all_orders')}</Option>
              <Option value={1}>{t('webstore')}</Option>
              <Option value={2}>{t('pos')}</Option>
              <Option value={3}>{t('self_checkout')}</Option>
            </Select>
            <Select
              className="my-auto"
              value={itemCount}
              style={{ width: 160 }}
              onChange={(obj) => setItemCount(obj)}
            >
              {_.map(counts, (c) => (
                <Option value={c}>{c}</Option>
              ))}
            </Select>
            <RangePicker
              format="YYYY-MM-DD"
              value={[
                dayjs(dates?.start),
                dayjs(dates?.end)
              ]}
              presets={dateRangePresets(t)}
              onChange={(date, dateString) => {
                const temp = {
                  start: dateString[0],
                  end: dateString[1]
                };
                setDates(temp);
              }}
            />
            <div className="mt-1 mx-auto md:mx-1">
              <span className="mt-2 md:ml-1">{t('compare_with_previous_year')}</span>
              <Switch
                className="md:ml-1"
                checked={compareLastYear}
                onChange={(checked) => setCompareLastYear(checked)}
              />
            </div>
            <div style={{ width: 400 }}>
              <Input.Search
                placeholder={t('item_search_placeholder')}
                allowClear
                loading={loading}
                enterButton={t('search')}
                size="large"
                onChange={(obj) => setSearchTerm(obj.target.value)}
                onSearch={(value) => getData()}
              />
            </div>
            {/* <Button
              type="primary"
              size="small"
              loading={loading}
              onClick={() => getData()}
            >
              {t('reload')}
            </Button> */}
          </div>
          <Spin spinning={loading}>
            <div style={{ height: 300 }}>
              <Bar
                data={{
                  labels: _.map(data?.comparisons, (s) => `${s.brand} ${s.name} ${s.description}`.trim()),
                  datasets: [
                    {
                      label: data?.previousLabel,
                      borderColor: config?.contents?.data?.secondaryColor,
                      backgroundColor: `${config?.contents?.data?.secondaryColor}cc`,
                      data: _.map(data?.comparisons, (x) => x.previousSalesTotal),
                    },
                    {
                      label: data?.currentLabel,
                      borderColor: config?.contents?.data?.primaryColor,
                      backgroundColor: `${config?.contents?.data?.primaryColor}cc`,
                      data: _.map(data?.comparisons, (x) => x.currentSalesTotal),
                    },
                  ],
                }}
                plugins={[ChartDataLabels, ZoomPlugin]}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [{
                      ticks: {
                        beginAtZero: true,
                        callback: (value, index, ticks) => toCurrency(value)
                      }
                    }],
                  },
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: false,
                    },
                    zoom: {
                      pan: {
                        enabled: true,
                        mode: 'x',
                        speed: 20,
                        threshold: 0,
                        onPan: (chart) => console.log('I\'m panning!!!'),
                        onPanComplete: (chart) => console.log('I was panned!!!')
                      },
                      zoom: {
                        enabled: true,
                        drag: true,
                        mode: 'x',
                        speed: 1,
                        threshold: 0,
                        sensitivity: 0,
                        onZoom: (chart) => console.log('I\'m zooming!!!'),
                        onZoomComplete: (chart) => console.log('I was zoomed!!!')
                      }
                    },
                    datalabels: {
                      anchor: 'end',
                      offset: 8,
                      rotation: 330,
                      color: 'black',
                      formatter: (value, context) => toCurrency(value),
                      labels: {
                        title: {
                          font: {
                            weight: 'regular'
                          }
                        }
                      }
                    }
                  },
                }}
              />
            </div>
            <Table
              bordered
              size="small"
              dataSource={data?.comparisons}
              columns={_.filter(tableColumns, (c) => !c.exportOnly)}
              title={() => (
                <div className="flex">
                  <Popover
                    className=""
                    trigger="click"
                    content={(
                      <div className="grid grid-cols-4 gap-4">
                        {_.map(_.filter(tableColumns, (c) => !c.exportOnly), (c) => (
                          <Checkbox
                            checked={!c.hidden}
                            onChange={(value) => toggleVisibility(c.key)}
                          >
                            {c.title}
                          </Checkbox>
                        ))}
                      </div>
                    )}
                  >
                    <Button className="ml-auto font-bold text-blue-500 hover:text-blue-800 border-none bg-transparent">
                      {t('show_hide_columns')}
                    </Button>
                  </Popover>
                  <Button
                    className="mt-1"
                    size="small"
                    icon={<CloudDownloadOutlined />}
                    onClick={() => {
                      const shownColumns = _.filter(tableColumns, (c) => !c.hidden);
                      const tempFields = { };
                      for (let i = 0; i < shownColumns.length; i++) {
                        tempFields[shownColumns[i].key] = shownColumns[i].title;
                      }
                      saveAsCsv({
                        data: _.filter(data.comparisons, (c) => !c.hidden),
                        fields: tempFields,
                        filename: `itemTrends_${dates.start}_${dates.end}`,
                      });
                    }}
                  >
                    {t('export_csv')}
                  </Button>
                </div>
              )}
            />
          </Spin>
        </div>
      </Modal>
    </Card>
  );
}

export default ItemsTrends;
